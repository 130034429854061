'use client';

import React, { useEffect } from 'react';
import { Helmet } from '../../components/Helmet';
import { BookingLayout } from '../../containers/BookingJourney';
import { LandingMain } from '../../containers/BookingJourney/components/landing';
import { BASE_URL } from '../../constants';
import { useFiltersStore } from '../../containers/BookingJourney/context';
import { useSegmentPage } from '../../hooks/useSegmentPage';
import SittingHeroImgMobile from '../../containers/BookingJourney/assets/Sitting_M.jpg';
import SittingHeroImgDesktop from '../../containers/BookingJourney/assets/Sitting_D.jpg';
import { OvernightLinks } from '../../containers/BookingJourney/components/landing/service-links/links';
import { sittingFaqsData } from '../../data/sitting-faqs';
import { useSetIsOnDemand } from '../../hooks';
import { tryCatch } from '../../utils';
import { getServicePageResponse } from '../../hooks/api/getServicePageData';
import { ServiceLandingPageProps } from '../../types/app/service-landing-page';
import { KustomerChatScript } from '@/wag-react/components/kustomer-chat/KustomerChatScript';
const meta = {
  title: 'The Best Dog Sitters Near You | Wag!',
  description: 'Schedule a sleepover for your dog in the comfort of your own home, for as many nights as you need. Great for house sitting and busy schedules.'
};
const DogSitting = ({
  modal
}: ServiceLandingPageProps) => {
  const canonicalUrl = `${BASE_URL}/dog-sitting`;
  const setFilters = useFiltersStore(state => state.setFilters);
  useEffect(() => {
    setFilters({
      service: 'sitting'
    });
  }, [setFilters]);
  useSetIsOnDemand(false);
  const {
    execute: executeSegmentPage
  } = useSegmentPage();
  useEffect(() => {
    executeSegmentPage({
      name: 'Booking Journey - Dog Sitting Landing'
    });
  }, [executeSegmentPage]);
  return <>
      <Helmet canonical={canonicalUrl} title={meta.title} description={meta.description} robots="index, follow" openGraph={{
      description: meta.description,
      title: meta.title,
      siteName: 'WagWalking',
      type: 'website',
      url: canonicalUrl
    }} twitter={{
      title: meta.title,
      description: meta.description,
      site: '@WagWalking'
    }} data-sentry-element="Helmet" data-sentry-source-file="index.tsx" />
      <LandingMain heroProps={{
      heroMobileImg: SittingHeroImgMobile,
      heroDesktopImg: SittingHeroImgDesktop,
      taglineLink: '/dog-boarding',
      tagline: 'Dog Boarding',
      taglineText: 'Do you need a Caregiver to look after your dog in their home? Check out our ',
      heroTitleProps: {
        title: 'Local, trusted dog sitting'
      }
    }} {...modal && {
      promoModalProps: {
        headerText: modal.title,
        subHeaderText: modal.caption,
        promoCode: modal.promoCode,
        offerExpiry: modal.expiration,
        msBeforeShow: modal.msBeforeShow
      }
    }} wagStates={{
      title: `Trusted dog sitters near you`
    }} descriptionProps={{
      descriptionTitle: 'What makes Dog Sitting with Wag! so great?'
    }} howToBookProps={{
      howToBookTitle: 'How overnight dog sitting works'
    }} serviceLinks={{
      LinkSection: OvernightLinks,
      showCatLink: true
    }} localExpertProps={{
      serviceType: 'Sitting'
    }} faqProps={{
      items: sittingFaqsData
    }} serviceDescription={{
      title: 'Trusted local dog sitters',
      description: "Book with a highly-rated pet sitter who will care for your pets in your home when you're out of town. Whether it's just for the weekend, date night, or several weeks, local dog sitters and pet care givers on Wag! are available to help."
    }} data-sentry-element="LandingMain" data-sentry-source-file="index.tsx" />

      <KustomerChatScript data-sentry-element="KustomerChatScript" data-sentry-source-file="index.tsx" />
    </>;
};
DogSitting.Layout = BookingLayout;
export const getStaticProps = async () => {
  const slug = 'dog-sitting';
  const [servicePageResponse] = await tryCatch(() => getServicePageResponse(slug));
  return {
    props: {
      tailwind: true,
      ...(servicePageResponse?.modal && {
        modal: servicePageResponse.modal
      })
    },
    revalidate: 10 // Revalidate every 10 seconds
  };
};
export default DogSitting;