export const sittingFaqsData = [
  {
    question: 'What is Wag!?',
    answer:
      'Wag! is the #1 app for pet parents -- offering 5-star dog walking, pet sitting, veterinary care, and training services nationwide. Book convenient pet care in your neighborhood with the Wag! app. Whether you’re looking for daily walks, planning a trip, stuck at work, or just want your best friend to have some company - any day, anytime pet care is available through the app.',
  },
  {
    question: "Who's watching my pet?",
    answer:
      'Wag! pairs your furry family member with an experienced pet sitter in your neighborhood. Your highly-rated sitter will take care of your pet at your home, for the day, or night (or month). Every booking includes unlimited playtime, potty breaks, and the entire day and night can be followed through video and photo updates. Many sitters are lifelong pet owners and every sitter is thoroughly background checked and insured.',
  },
  {
    question: 'Is Wag! right for my pet?',
    answer: [
      "The Wag! matchmaking process is designed to find your purr-fect match for any cat or dog. On Wag! you can find and book a sitter with experience caring for puppies and kittens, older pets, disabled pets, pets that require medication, pets with separation anxiety, and pets who need lots of playtime. Helpful tip: Make sure to inform your Sitter of all the information they'll need to know to best take care of your pet!",
    ].join('\n'),
  },
  {
    question: 'How much does a Wag! sitting service cost?',
    answer: [
      'On average, the cost of an overnight stay for one dog is $39 - $59*. The cost is based on the price set by the Pet Caregiver and varies by region and any additional fees that may apply. The estimated rate will be displayed at the bottom of the screen during booking, with a final price provided when you match with a Pet Caregiver.',
      '*These are estimated prices. The final price may be higher or lower.',
    ].join('\n'),
  },
  {
    question: 'Where will my pet be staying?',
    answer: 'Your pet will be staying in your home with a pet sitter.',
  },
  {
    question: 'I have more than one dog. Is there an additional fee that I will need to pay?',
    answer: 'Each additional dog costs an additional fee that is set based on the individual Caregiver’s rate.',
  },
  {
    question: 'How do I evaluate sitters?',
    answer:
      'Before any sitter begins working for Wag!, he or she must first undergo a thorough vetting process (which includes pet handling, safety tests, phone screening, and background checks). Many of the sitters have worked as veterinary technicians or shelter volunteers. The majority have been lifelong pet owners and caretakers. 95% of Wag! sittings result in a perfect, 5-star review.',
  },
  {
    question: 'How do I pay for the service?',
    answer:
      'Wag! accepts all major credit cards, including VISA, MasterCard and American Express. Never pay by cash or check as this makes your booking ineligible for premium insurance and support.',
  },
  {
    question: 'When will I be charged for service?',
    answer:
      'Your card is charged after the service is complete. Wag! will hold onto the funds and release payment to your walker or sitter within 48 hours of the service being completed.',
  },
];
